<template>
    <div>
        <div style="display: flex; justify-content: end">
            <b-button variant="outline-primary mr-3 mb-3" @click="$emit('openAddDiscountModal')">
            <span
                class="d-flex"><i
                class="ri-add-line mr-2"></i>{{ changeText($t('add_discount').toUpper()) }}</span>
            </b-button>
        </div>

        <b-table
            bordered
            striped
            responsive
            :items="discountTable"
            :fields="discountTableFields"
            show-empty
            class="mb-5 table-dropdown no-scrollbar border rounded">
            <template #cell(dropdownTable)="row">
                <div class="d-flex">
                    <b-button class="mr-3" variant="outline-primary" size="xs"
                              @click="edit(row.item)">{{ $t('edit')|toUpperCase }}
                    </b-button>

                    <b-button class="text-danger" variant="link" size="xs"
                              @click="deleteDiscount(row.item.id)">
                        {{ $t('delete').toUpper() }}
                    </b-button>
                </div>
            </template>
        </b-table>
    </div>
</template>
<script>

import Discount from "@/services/Discount";
import qs from "qs";
export default {
   props:{
       student_program_id:{},
       get_discount_list:{}
   },
    data() {
        return {
            discountTable:null,
            discountTableFields:[
                {
                    key: 'type_name',
                    label: this.toUpperCase('type')
                },
                {
                    key: 'discount',
                    label: this.toUpperCase('discount')
                },
                {
                    key: 'applied_discount',
                    label: this.toUpperCase('applied_discount'),
                },
                {
                    key: 'remaining_discount',
                    label: this.toUpperCase('remaining_discount'),
                },
                {
                    key: 'explanation',
                    label: this.toUpperCase('explanation'),
                },
                {key: 'dropdownTable', label: '#', tdClass:'width-100'}
            ],
        }
    },
    created() {
       this.getDiscountData()

    },
    watch:{
        get_discount_list(value){
           if (value){
               this.getDiscountData()
           }
      }
    },
    methods: {
       getDiscountData(){
           const config = {
               params: {
                   filter:{
                       student_program_id:this.student_program_id
                   }
               },
               paramsSerializer: (params) => qs.stringify(params, {encode: false})
           };
           Discount.getDiscount(config)
               .then(response=>{
                   this.discountTable=response.data.data
               }).catch(err=>{
                   this.showErrors(err)
           })

       },
        async deleteDiscount(id) {
            this.deleteModal(() => {
                Discount.del(id)
                    .then(response => {
                        this.getDiscountData();
                        this.toast(response.data);
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    });
            });

        },
        edit(row) {
           this.$emit('openAddDiscountModal', row);
        },

    }
}
</script>
